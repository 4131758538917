@import "./header.scss";
@import "./footer.scss";

.fixeds{
  background-color: rgba($color: $black, $alpha: 0.9) !important;
  transition:  all 0.5s ;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.205);
  position: fixed;
  top: 0;
  z-index: 999;
  padding: 10px 0px;
  width: 100% !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.swiper-button-next,
.swiper-button-prev {
  background: -webkit-linear-gradient(#f8cf3a, #2e2814);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.formik-error {
  padding: 2px 0 2px 8px;
  color: #ef4444;
  font-family: $pr;
  font-size: 14px;
}

.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: 2px solid #ccc;
  background-color: #fff;
  border-radius: 0.25rem;
}

.custom-checkbox:checked {
  border-color: #ecc551;
  background-color: #ecc551;
}

.custom-checkbox:checked::before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white"%3E%3Cpath stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" /%3E%3C/svg%3E');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

// .formik-loading {
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }