@import './variables';
@import './typography';
@import './mixins';

footer {
  min-height: 600px;
  padding-top: 150px;

  background-image: url('../assets/images/home/footerbg.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media (max-width: 1024px) {
    padding-bottom: 70px;
  }

  .c-flex {
    display: flex;
    margin-bottom: 50px;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
    @media (max-width: 576px) {
      margin-top: -150px;
    }
  }
  .neflex {
    @media (max-width: 1024px) {
      margin-top: -220px;
      margin-bottom: 50px !important;
    }
  }

  .sbox{
    background: url('../assets/images/team-up-withus.png') !important;
    background-repeat: no-repeat !important;
    background-position: right center !important;
    background-size: cover !important;
    @media (max-width: 1024px){
      flex-direction: column;
    }
    h3{
      color: $white !important;
      margin-bottom: -100px;
      @media (max-width: 1024px){
        margin-bottom: 20px;
      }
    }
    .d-flex{
      button {
        font-family: $pb;
        color: $white;
        text-transform: uppercase;
        padding: 12px 30px;
        border-radius: 5px;
        border: 2px solid $secondary !important;
        background-color: transparent !important;
        @include btn-animation;
        &:hover {
          border: 2px solid $secondary !important;
          background-color: $secondary !important;
          color: $white;
        }
        @media (max-width: 576px) {
          padding: 8px 10px;
          font-size: 10px;
        }
      }
    }
  }
  .newbox{
    width: 50%;
    display: flex;
    flex-direction: column;
    border-radius: 0px !important;
    padding-left: 50px !important;
    @media (max-width: 1024px){
      width: 100%;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
    @media (max-width: 576px){
      padding-left: 20px !important;
    }
    .d-flex{
      input{
        width: 30%;
        @media (max-width: 1024px){
         width: 100%;
        }
      }
    }
  }
  .box {
    position: relative;
    margin-top: -220px;
    border-radius: 10px;
    padding: 30px 20px;
    background: rgb(249, 216, 69);
    background: linear-gradient(
      90deg,
      rgba(249, 216, 69, 1) 0%,
      rgba(251, 232, 152, 1) 100%
    );
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px) {
      flex-direction: column;
      margin-bottom: 50px;
    }
    .d-flex {
      i {
        margin-left: 10px;
      }
      a {
        @media (max-width: 576px) {
          width: 100%;
          display: inline-block;
        }
      }
      a,
      i {
        @include heading(18px, $pb, $black, capitalize);
        text-decoration: none;
        @media (max-width: 576px) {
          font-size: 18px;
        }
        &:hover {
          color: $black2;
        }
      }
    }
    h3 {
      @include heading(30px, $pb, $black, capitalize);
      @media (max-width: 1536px) {
        font-size: 22px;
      }
      @media (max-width: 576px) {
        font-size: 18px;
      }
    }
    .d-flex {
      display: flex;
      align-items: baseline;
      flex-direction: row;
      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: self-start;
      }
      input {
        font-family: $pb;
        color: $primary;
        padding: 12px 24px;
        border-radius: 5px;
        border: 1px solid rgba($color: $black, $alpha: 0.2);
        min-width: 300px;
        background-color: $white;
        margin-right: 5px;
        @include btn-animation;
        color: $black;
        outline: none !important;
        @media (max-width: 1024px) {
          margin-bottom: 10px;
        }
        @media (max-width: 576px) {
          min-width: unset;
          padding: 8px 10px;
          width: 100%;
          font-size: 10px;
        }
      }
      button {
        font-family: $pb;
        color: $white;
        text-transform: uppercase;
        padding: 12px 30px;
        border-radius: 5px;
        border: 2px solid $black;
        background-color: $black;
        // @include btn-animation;
        &:hover:enabled {
          border: 2px solid $secondary;
          background-color: $secondary;
          color: $white;
        }
        @media (max-width: 576px) {
          padding: 12px 10px;
          font-size: 10px;
        }

        &.loading {
          padding: 9px 30px;
  
          @media (max-width: 576px) {
            padding: 5px 24px;
          }
        }
  
        .formik-loading span span{
          background-color: $white !important;
        }
      }
    }
  }

  .c-flex.neflex .box .d-flex input {
    min-width: unset;
    width: 100%;
  }

  .logo {
    margin-bottom: 20px;
    @media (max-width: 1024px) {
      margin: 0;
    }
  }
  .fborder {
    border-left: 1px solid #3a3a3a;
    padding-left: 35px;
    min-height: 350px;
    @media (max-width: 1024px) {
      min-height: 270px;
    }
    @media (max-width: 576px) {
      min-height: 200px;
    }
  }
  .sfborder {
    @media (max-width: 1024px) {
      border-left: 1px solid #3a3a3a;
      padding: 10px 0 0 0;
      min-height: 270px;
    }
    @media (max-width: 576px) {
      min-height: 65px;
    }
  }
  p {
    @include paragraph(15px, $pr, rgba($color: $white, $alpha: 1));
    margin-bottom: 2px;
  }
  h5 {
    border-left: 5px solid $secondary;
    padding-left: 30px;
    margin-left: -40px;
    @include paragraph(22px, $pb, rgba($color: $white, $alpha: 1));
    @media (max-width: 1024px) {
      margin-top: 30px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    margin-top: 20px;
    li {
      line-height: 1.8;
      a {
        margin-bottom: 0;
        text-decoration: none;
        @include paragraph(16px, $pr, rgba($color: $white, $alpha: 1));
        &:hover {
          color: $secondary;
          text-decoration: underline;
        }
      }
    }
  }
  .d-flex {
    flex-direction: column;
    @media (max-width: 576px) {
      margin-top: 0;
      flex-direction: row;
      justify-content: space-around;
    }
    a {
      margin-top: 10px;
      svg {
        @include paragraph(22px, $pb, rgba($color: $white, $alpha: 1));
        &:hover {
          color: $secondary;
        }
      }
    }
    .location {
      width: 20% !important;
      @media (max-width: 1024px) {
        width: unset !important;
      }
      img {
        padding-right: 10px;
      }
    }
    .detail {
      width: 80% !important;
      @media (max-width: 1024px) {
        width: unset !important;
      }
      h6 {
        margin-bottom: 0;
        @include paragraph(14px, $pr, rgba($color: $white, $alpha: 1));
      }
      a {
        margin-bottom: 0;
        text-decoration: none;
        @include paragraph(14px, $pr, rgba($color: $white, $alpha: 1));
        &:hover {
          color: $secondary;
        }
      }
    }
  }
  .copy {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #3a3a3a;
    p {
      text-align: center;
      margin-bottom: 0px;
      padding: 10px 0px;
      @include paragraph(14px, $pr, rgba($color: $white, $alpha: 1));
    }
  }

  .links {
    margin-top: 28px;
    color: rgba($color: $white, $alpha: 1);
    text-align: left;
    a {
      margin-bottom: 0;
      line-height: 2;
      text-decoration: none;
      white-space: nowrap;
      @include paragraph(16px, $pr, rgba($color: $white, $alpha: 1));
      &:hover {
        color: $secondary;
        text-decoration: underline;
      }
    }
  }
}
// go to  top
.top {
  #return-to-top {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    background: $secondary;
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    border-radius: 35px;
    // display: none;
    opacity: 0;
    transition: all 0.3s ease;
    @media (max-width: 576px) {
      right: 0px;
      transform: scale(0.7);
    }
  }
  #return-to-top i {
    position: relative;
    left: 3px;
    top: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -8px;
    margin-left: -5px;
    font-size: 19px;
    color: $white;
    transition: all 0.3s ease;
  }

  #return-to-top:hover i {
    color: $white;
    top: 18px;
  }
}
.modal {
  .modal-content {
    background-color: $white;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    @media (max-width: 1024px) {
      margin-top: 50px;
    }

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid $grey;

      h5 {
        @include heading(25px, $pb, $black, capitalize);
        @media (max-width: 576px) {
          font-size: 30px;
        }
      }

      .close {
        cursor: pointer;
        font-size: 25px;
        color: $grey;
        background-color: transparent;
        border: none;
      }
    }

    .modal-body {
      padding: 20px 0;
      .form-group {
        margin-bottom: 20px;
        label {
          @include paragraph(18px, $pr, $grey);
          margin-bottom: 2px;
          margin-left: 5px;
        }
        input {
          font-family: $pb;
          color: $primary;
          padding: 12px 10px;
          border-radius: 5px;
          border: 1px solid rgba($color: $black, $alpha: 0.2);
          background-color: $white;
          margin-right: 5px;
          @include btn-animation;
          color: $black;
          outline: none !important;
          @media (max-width: 1024px) {
            margin-bottom: 10px;
          }
          @media (max-width: 576px) {
            padding: 8px 10px;
            width: 100%;
            font-size: 10px;
          }
        }
      }
      button {
        font-family: $pb;
        color: $white;
        text-transform: uppercase;
        padding: 12px 30px;
        border-radius: 5px;
        border: 2px solid $black;
        background-color: $black;
        @include btn-animation;
        &:hover {
          border: 2px solid $secondary;
          background-color: $secondary;
          color: $white;
        }
        @media (max-width: 576px) {
          padding: 8px 10px;
          font-size: 10px;
        }
      }
      p {
        @include paragraph(16px, $pr, $black2);
      }
    }

    .modal-footer {
      display: flex;
      justify-content: flex-end;
      padding-top: 10px;
      border-top: 1px solid $grey;
    }
  }
}
